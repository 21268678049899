import React from 'react';
import { Col, Row } from 'reactstrap';
import {
  Secao,
  SecaoCabecalho,
  SecaoBotaoVoltar,
} from '@alweb-merkator/shared/components/Secao';
// import SecaoConectech from './SecaoConectech';
import SecaoEstacao3 from './SecaoEstacao3';

// import { Container } from './styles';

function SecaoEstacao3Conectech() {
  return (
    <Secao className="mb-4">
      <SecaoCabecalho className="mb-4">
        <SecaoBotaoVoltar />
      </SecaoCabecalho>

      <Row>
        <Col md="12">
          <SecaoEstacao3 idPage={462} />
        </Col>
        {/* <Col md="6">
          <SecaoConectech idPage={37} />
        </Col> */}
      </Row>
    </Secao>
  );
}

export default SecaoEstacao3Conectech;
