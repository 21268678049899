import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { useParams } from 'react-router';
// import { HashLink } from 'react-router-hash-link';
// import iconBussTraslado from '../../assets/icon-buss-traslado.svg';
// import iconBussTowerTraslado from '../../assets/icon-buss-tower-traslado.svg';
// import iconTwoBussTraslado from '../../assets/icon-two-buss-traslado.svg';
import transporteOficial from '../../assets/logo_brocker_turismo_svg.svg';

import { Modal, Button } from 'react-bootstrap';

import Head from '@alweb-merkator/shared/components/Head';
import {
  Styles,
  Secao,
  Tradutor,
  SecaoMenu,
  SecaoCabecalho,
  SecaoTitulo,
  SecaoMenuNav,
  SecaoMenuItem,
  SecaoMenuLista,
  SecaoBotaoVoltar,
} from './styles';

const Traslado = (props) => {
  const { t } = useTranslation();
  // const { lang } = useParams();
  const [show, setShow] = useState(false);
  const [textTraslado, setTextTraslado] = useState();

  useEffect(() => {
    setTextTraslado('* Em breve horarios disponíveis. ');
  }, []);

  const handleClose = () => setShow(false);
  const linkWhatsCompra =
    'https://eventos.brockerturismo.com.br/eventos/sicc-2025';

  return (
    <div className="pagina-traslado">
      <Styles />
      <Head
        title={t('paginas.traslado.titulo')}
        description={t('paginas.traslado.titulo')}
      />
      <Secao className="mb-4">
        <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <Tradutor path="paginas.traslado.titulo" />
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho>

        <div className="row">
          <div className="col-sm-8 text-justify">
            <div
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.description'),
              }}
            ></div>
            {/* <div className="mb-2">
              <p
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textTrasnporteOficial'),
                }}
              ></p>
            </div> */}
          </div>
          <div className="col-sm-4 text-center">
            <div className="mb-2">
              <p
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textTrasnporteOficial'),
                }}
              ></p>
            </div>
            <div className="box-comprar">
              <a
                href={linkWhatsCompra}
                target="_blank"
                rel="noreferrer"
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textCompreAqui'),
                }}
              ></a>
            </div>
            <p>
              <img
                src={transporteOficial}
                alt="Transporte Planalto"
                className="max-height-60"
              />
            </p>
          </div>
          <div className="col-sm-4">
            {/* <a
              className="btn btn-block btn-secondary"
              href={linkWhatsCompra}
              target="_blank"
              rel="noreferrer"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textBotaoComprar'),
                }}
              ></div>
            </a> */}
            {/* <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Domingo`}
              className="btn btn-block btn-primary"
              title="Veja os horários de traslado no domingo dia 30/06/2024"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textEventoDiaUm'),
                }}
              ></div>
            </HashLink> */}
            {/* <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Quarta`}
              className="btn btn-block btn-primary"
              title="Veja os horários de traslado na quarta-feira dia 23/05/2023"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textEventoDiaQuatro'),
                }}
              ></div>
            </HashLink> */}
            {/* <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Segunda`}
              className="btn btn-block btn-primary"
              title="Veja os horários de traslado na segunda-feira dia 01/07/2024"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textEventoDiaDois'),
                }}
              ></div>
            </HashLink> */}
            {/* <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Quinta`}
              className="btn btn-block btn-primary"
              title="Veja os horários de traslado na quinta-feira dia 04/07/2024"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textEventoDiaCinco'),
                }}
              ></div>
            </HashLink> */}
          </div>
          <div className="col-sm-4">
            {/* <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Valores`}
              className="btn btn-block btn-secondary"
              title="Veja os valores dos traslados"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textBotaoValores'),
                }}
              ></div>
            </HashLink> */}
            {/* <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Segunda`}
              className="btn btn-block btn-primary"
              title="Veja os horários de traslado na segunda-feira dia 01/07/2024"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textEventoDiaDois'),
                }}
              ></div>
            </HashLink> */}
            {/* <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Terca`}
              className="btn btn-block btn-primary"
              title="Veja os horários de traslado na terça-feira dia 14/05/2024"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textEventoDiaTres'),
                }}
              ></div>
            </HashLink> */}
            {/* <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Quarta`}
              className="btn btn-block btn-primary"
              title="Veja os horários de traslado na quarta-feira dia 23/05/2023"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textEventoDiaQuatro'),
                }}
              ></div>
            </HashLink> */}
            {/* <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Caravanas`}
              className="btn btn-block btn-secondary"
            >
              Caravanas
            </HashLink> */}
          </div>
        </div>
      </Secao>

      {/* <Secao className="mb-4" id="Domingo">
        <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <div
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textEventoDiaUm'),
              }}
            ></div>
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho>
        <div className="row">
          <div className="col-sm-6">
            <h2>{'POA > GRAMADO'}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textTitleDescriptionDiaUm'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textDescriptionDiaUm'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textAlert'),
              }}
            ></p>
          </div>
          <div className="col-sm-6"></div>
        </div>
      </Secao> */}

      {/* <Secao className="mb-4" id="Segunda">
        <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <div
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textEventoDiaDois'),
              }}
            ></div>
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho>
        <div className="row">
          <div className="col-sm-6">
            <h2>{'POA > GRAMADO'}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textTitleDescriptionDiaDois'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textDescriptionDiaDois'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textAlert'),
              }}
            ></p>
          </div>
          <div className="col-sm-6"></div>
        </div>
      </Secao> */}

      {/* <Secao className="mb-4" id="Terca">
        <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <div
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textEventoDiaTres'),
              }}
            ></div>
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho>

        <div className="row">
          <div className="col-sm-6">
            <h2>{'POA > GRAMADO'}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textTitleDescriptionDiaTres'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textDescriptionDiaTres'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textAlert'),
              }}
            ></p>
          </div>
          <div className="col-sm-6">
            <h2>{'GRAMADO > POA'}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textTitleDescriptionDiaTresV'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textDescriptionDiaTresV'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textAlert'),
              }}
            ></p>
          </div>
        </div>
      </Secao> */}

      {/* <Secao className="mb-4" id="Quarta">
        <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <div
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textEventoDiaQuatro'),
              }}
            ></div>
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho>

        <div className="row">
          <div className="col-sm-6">
            <h2>{'GRAMADO > POA'}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textTitleDescriptionDiaQuatro'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textDescriptionDiaQuatro'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textAlert'),
              }}
            ></p>
          </div>
        </div>
      </Secao> */}

      {/* <Secao className="mb-4" id="Quinta">
        <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <div
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textEventoDiaCinco'),
              }}
            ></div>
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho>

        <div className="row">
          <div className="col-sm-6">
            <h2>{'GRAMADO > POA'}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textTitleDescriptionDiaCinco'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textDescriptionDiaCinco'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textAlert2'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textAlert'),
              }}
            ></p>
          </div>
          <div className="col-sm-6"></div>
        </div>
      </Secao> */}

      <Secao className="mb-4" id="Valores">
        {/* <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <h1
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textBotaoValores'),
              }}
            ></h1>
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho> */}

        {/* <div className="container-valores">
          <div className="row">
            <div className="col-md-9">
              <div
                className="texto"
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.descriptionValor'),
                }}
              ></div>
              <div className="row">
                <div className="col-md-4">
                  <div className="box-ticket min-height-box-ticket">
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.comboTicketUnico'),
                      }}
                    ></h2>
                    <img src={iconTwoBussTraslado} alt="Valores de Traslados" />
                    <p
                      className="texto"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.comboDescriptionUnico'),
                      }}
                    ></p>
                    <p
                      className="price"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.precoComboTicketUnico'),
                      }}
                    ></p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.porPessoa'),
                      }}
                    ></p>
                    <p
                      className="font-size-11px"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.exceptionPrice'),
                      }}
                    ></p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="box-ticket min-height-box-ticket">
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.titleTicketUnico'),
                      }}
                    ></h2>
                    <img src={iconBussTraslado} alt="Valores de Traslados" />
                    <p
                      className="texto"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.titleDescriptionUnico'),
                      }}
                    ></p>
                    <p
                      className="price"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.precoTicketUnico'),
                      }}
                    ></p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.porPessoa'),
                      }}
                    ></p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="box-ticket bg-secondary min-height-box-ticket">
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.titleTicketAvulso'),
                      }}
                    ></h2>
                    <img
                      src={iconBussTowerTraslado}
                      alt="Valores de Traslados"
                    />
                    <p
                      className="texto"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.descriptionTicketAvulso'),
                      }}
                    ></p>
                    <p
                      className="price"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.precoTicketAvulso'),
                      }}
                    ></p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.porPessoa'),
                      }}
                    ></p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="box-ticket bg-secondary min-height-box-ticket">
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.titleComboFeira'),
                      }}
                    ></h2>
                    <img
                      src={iconBussTowerTraslado}
                      alt="Valores de Traslados"
                    />
                    <p
                      className="texto"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.descriptionComboFeira'),
                      }}
                    ></p>
                    <p
                      className="price"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.precoComboFeira'),
                      }}
                    ></p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.porPessoa'),
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="container-button">
                <div className="box-comprar">
                  <a
                    href={linkWhatsCompra}
                    target="_blank"
                    rel="noreferrer"
                    dangerouslySetInnerHTML={{
                      __html: t('paginas.traslado.textCompreAqui'),
                    }}
                  ></a>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('paginas.traslado.textTrasnporteOficial'),
                  }}
                ></p>
                <img
                  src={transporteOficial}
                  alt="Trasnporte Oficial do Evento"
                  className="max-height-60"
                />
              </div>
            </div>
          </div>
        </div> */}
      </Secao>
      <Modal
        {...props}
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Compra de passagens
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{textTraslado}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Traslado;
